table {
  margin: 16px 0;
}
.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
[data-theme="dark"] .site-form-in-drawer-wrapper {
  border-top: 1px solid #303030;
  background: #1f1f1f;
}
.currencyAmount {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
}