table {
  margin: 16px 0;
}

.currencyAmount {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.currencyAmount-ccy {
  margin-right: auto;
}
.currencyAmount-amount {
  margin-left: auto;
}